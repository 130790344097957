module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/favicon/favicon.svg","icons":[{"src":"static/images/icon_48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_128.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"static/images/icon_1024.png","sizes":"1024x1024","type":"image/png","purpose":"maskable"}],"name":"Kelvin Leung | Experienced Javascript full-stack developer","short_name":"Kelvin Leung | Experienced Javascript full- stack developer","description":"Adept Javascript full-stack developer skilled in frontend/backend, databases, and mobile app dev for Android/iOS. Contact for web dev needs.","lang":"en","start_url":"/","background_color":"#0e2f44","theme_color":"#0e2f44","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e75a35edc89876277c27e77b327e32ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-FCPT6L6H7G"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kelvinleung.me"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
